<template>
  <div class="col-12 mt-3">
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-end">
        <!--          First-->
        <li
          :class="`page-item-custom ${firstPageSelected() ? 'disabled' : ''}`"
        >
          <button
            @click.prevent="selectFirstPage()"
            :disabled="firstPageSelected()"
            :tabindex="firstPageSelected() ? -1 : 0"
            :class="`page-link-custom font-weight-bold`"
          >
            <font-awesome-icon icon="step-backward" />
          </button>
        </li>

        <!--          Previous-->
        <li
          :class="`page-item-custom ${firstPageSelected() ? 'disabled' : ''}`"
        >
          <button
            @click.prevent="prevPage()"
            :disabled="firstPageSelected()"
            :tabindex="firstPageSelected() ? -1 : 0"
            :class="`page-link-custom font-weight-bold`"
            aria-label="Previous"
          >
            <font-awesome-icon icon="chevron-left" />
          </button>
        </li>

        <!--      Inside Link-->
        <li
          v-for="page in pages"
          :key="page.content"
          :class="`page-item-custom ${page.selected ? 'active' : ''} ${
            page.disabled ? 'disabled' : ''
          }`"
        >
          <button
            v-if="page.disabled"
            :class="`page-link-custom font-weight-bold`"
            tabindex="0"
          >
            ...
          </button>
          <button
            v-else
            @click.prevent="handlePageSelected(page.index)"
            :class="`page-link-custom font-weight-bold`"
          >
            {{ page.content }}
          </button>
        </li>

        <!--          Next-->
        <li :class="`page-item-custom ${lastPageSelected() ? 'disabled' : ''}`">
          <button
            @click.prevent="nextPage()"
            :class="`page-link-custom font-weight-bold`"
            :tabindex="lastPageSelected() ? -1 : 0"
            aria-label="Next"
          >
            <font-awesome-icon icon="chevron-right" />
          </button>
        </li>

        <!--          Last-->
        <li :class="`page-item-custom ${lastPageSelected() ? 'disabled' : ''}`">
          <button
            @click.prevent="selectLastPage()"
            :tabindex="lastPageSelected() ? -1 : 0"
            :class="`page-link-custom font-weight-bold`"
          >
            <font-awesome-icon icon="step-forward" />
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    value: {
      type: Number,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    forcePage: {
      type: Number,
    },
    clickHandler: {
      type: Function,
      default: () => {
        return;
      },
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
    prevText: {
      type: String,
      default: "Prev",
    },
    nextText: {
      type: String,
      default: "Next",
    },
  },
  beforeUpdate() {
    if (this.forcePage === undefined) return;
    if (this.forcePage !== this.selected) {
      this.selected = this.forcePage;
    }
  },
  computed: {
    selected: {
      get: function () {
        return this.value || this.innerValue;
      },
      set: function (newValue) {
        this.innerValue = newValue;
      },
    },
    pages: function () {
      let items = {};
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          let page = {
            index: index,
            content: index + 1,
            selected: index === this.selected,
          };
          items[index] = page;
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2);

        let setPageItem = (index) => {
          let page = {
            index: index,
            content: index + 1,
            selected: index === this.selected,
          };

          items[index] = page;
        };

        let setBreakView = (index) => {
          let breakView = {
            disabled: true,
            breakView: true,
          };

          items[index] = breakView;
        };

        // 1st - loop thru low end of margin pages
        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i);
        }

        // 2nd - loop thru selected range
        let selectedRangeLow = 0;
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - halfPageRange;
        }

        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1;
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
        }

        for (
          let i = selectedRangeLow;
          i <= selectedRangeHigh && i <= this.pageCount;
          i++
        ) {
          setPageItem(i);
        }

        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1);
        }

        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1);
        }

        // 3rd - loop thru high end of margin pages
        for (
          let i = this.pageCount - 1;
          i >= this.pageCount - this.marginPages;
          i--
        ) {
          setPageItem(i);
        }
      }
      return items;
    },
  },
  data() {
    return {
      innerValue: 0,
    };
  },
  methods: {
    handlePageSelected(selected) {
      if (this.selected === selected) return;

      this.innerValue = selected;
      this.$emit("input", selected);
      this.clickHandler(selected);
    },
    prevPage() {
      if (this.selected <= 0) return;

      this.handlePageSelected(this.selected - 1);
    },
    nextPage() {
      if (this.selected >= this.pageCount) return;

      this.handlePageSelected(this.selected + 1);
    },
    firstPageSelected() {
      return this.selected === 0;
    },
    lastPageSelected() {
      return this.selected === this.pageCount - 1 || this.pageCount === 0;
    },
    selectFirstPage() {
      if (this.selected <= 0) return;

      this.handlePageSelected(0);
    },
    selectLastPage() {
      if (this.selected >= this.pageCount) return;

      this.handlePageSelected(this.pageCount - 1);
    },
  },
};
</script>

<style lang="css" scoped></style>
